import {
  useContext,
  createContext,
  useState,
} from 'react'

const GameContext = createContext()

export function GameProvider({ children }) {
  const [ticTacToe, setTicTacToe] = useState({
    message: '',
    playerSide: '',
    playerToken: '',
    computerSide: '',
    computerToken: '',
    gameOver: true,
    board: [
      ['', '', ''],
      ['', '', ''],
      ['', '', ''],
    ],
    winOptions: [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ],
  })

  return (
    <GameContext.Provider value={
      { ticTacToe, setTicTacToe }
    }>
      {children}
    </GameContext.Provider>
  )
}

export function useGameContext() {
  return useContext(GameContext)
}