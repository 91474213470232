import './Projects.css'

export default function Projects() {

  return (
    <section className='projects'>
      <div className="projects-heading">
        <h1>Some things I&apos;ve built</h1>
        <p>
          I am currently working on a few projects that I am excited about. I am also open to freelance opportunities. If you are interested in working with me please feel free to contact me<a id='email' href="mailto:melody@melodymcclure.com" >through my email</a> or my LinkedIn profile listed at the bottom of the page.
        </p>
        <p>
          Clicking on the title of the project will take you to additional information about it.
        </p>
      </div>
        <div id='project'>
        <a href="https://marsrover.melodymcclure.com/" target='_blank' rel='noreferrer'>
          <h2 id='project-description'>
            The Mars Rover Project
          </h2>
        </a>
        <p>
          This project was inspired by a bootcamp project called "Nasa Photo of the Day" that would access the NASA API and get a single image or video for the day. I decided I wanted to do something a little different and the result is this search site that allows you to search from the 4 rovers that have been sent to Mars based on their active service dates to view the images captured by that rover on any valid date that is selected.
        </p>
        {/* <p>
          <a href='https://github.com/MelodyLayne/MarsRoverPicApp#readme' target='_blank' rel='noreferrer'>This link goes to a more detailed explanation in the GitHub repository's README.md file</a>
        </p> */}
      {/* <div className="spacer" />
      </div>
      <div id='project'>
          <h2 id='project-description'>
            induco
        </h2>
        <p>This pre-seed startup has since closed down</p>
        <ul>
        <li>
            The purpose of induco was two-fold.
        </li>
          <li>
            One is to help consumers have more control of their personal data on the internet and rather than give it away for free we help them get paid for it.
          </li>
          <li>
          Two is to help accelerate the every day use of Web3 and blockchain technology by leveraging the best of the "Decentralized Web" with the best of what is currently available. We are building a program that uniquely marries Web2 and Web3 technologies.
          </li>
        </ul> */}
        <div className="spacer" />
      </div>
      <div id='project'>
        <a href="https://scenario.life/" target='_blank' rel='noreferrer'>
          <h2 id='project-description'>
            Scenario
          </h2>
        </a>
        <p>
          Scenario is an app that helps people make financial decisions based on the lifestyle they are working to achieve.
        </p>
        <p>
          My primary role was full stack development using Nextjs, Supabase, and Tailwind CSS.
        </p>
        <div className="spacer" />
      </div>
      <div id='project'>
        <h2>
          Non-Code Work
        </h2>
        <a href="https://www.oldschool.info/" target='_blank' rel='noreferrer'><h2>Old School Anti-Ageism Clearinghouse</h2></a>
        <p>My role here is to help manage the resources that are published on the site and make sure that search tags are applied in a thoughtful and useful manner. I am a freelance contractor with this organization.</p>
      </div>
    </section>
  )
}
