import LinkedIn from '../TechStack/images/LinkedIn.png'
import GitHub from '../TechStack/images/github.png'
import './Footer.css'

export default function Footer() {

  return (
    <section className='footer-info' >
      <p className='footer-text'>
        This site is build on a React library using custom event handlers and 90% of the code it took to build was written by me personally. If you have any questions or want to inquire about how to employ my services <a id='email' href="mailto:melody@melodymcclure.com" >you can send me an email</a> and I'll respond quickly, usually within 24 hours. Thanks for stopping by and have a great day!

      </p>
        <h4 >Find my profiles on LinkedIn and GitHub by clicking the icons below</h4>
        <div className='footer-links'>
          <a href='https://www.linkedin.com/in/melodylaynemcclure/'>
            <img id='linkedin' src={LinkedIn} alt='Melody McClure LinkedIn Profile' />
          </a>
          <a href='https://github.com/MelodyLayne'>
            <img id='github' src={GitHub} alt='Melody McClure GitHub Profile' />
          </a>
        </div>
      </section>
  )
}
