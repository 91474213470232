import Header from './components/Header';
import TechStack from './components/TechStack'
import Projects from './components/Projects';
import FullStack from './components/FullStack'
import Footer from './components/Footer';
import './style.css';

function App() {

	return (
		<>
			<Header	/>
			<TechStack />
			<Projects />
			<FullStack />
			<Footer />
		</>
	);
}

export default App;
