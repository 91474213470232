import './Header.css'

export default function Header() {
  return (
    <div className='header' >
        <h2 id='name'>Melody McClure</h2>
        <h4 id='subtitle'>Full Stack Web Developer</h4>
      </div>
  )
}
