import { useAppContext } from '../../context/contextProvider'
import TicTacToe from '../Games/TicTacToe'
import './FullStack.css'

export default function FullStack() {

  const { state, setState } = useAppContext()

  let num = (Math.floor(Math.random() * state.quotes.length))
  let quote = state.quotes[num]
  let newQuotes = state.quotes
  const newQuote = () => {
    newQuotes.splice(num, 1)
    setState({
      ...state,
      favoriteQuote: quote,
      quotes: newQuotes,
    })
  }


  return (
    <div className='info'>
      <div className='titles'>
        <h1>Some things about me:</h1>
      </div>
      <div className='texts'>
        <h3>I love a good quote! I built this component to show a different quote among my favorites every time you click the button.</h3>
        <p id='quote'>{state.favoriteQuote}</p>
        {state.quotes.length > 0
          ? <button id='quotes' onClick={newQuote}>See Another</button>
          : <h4>No more quotes</h4>
        }
      </div>
      <div className="spacer" />
      <div className='texts'>
        <h3>I built a Tic-Tac-Toe game when I was in a coding bootcamp. The computer generated player is more artificial and less intelligence though.</h3>
      </div>
        <TicTacToe />
      <div className="spacer" />
      <h3>Work I specialize in</h3>
      <div className='texts'>
        <p>
          I am primarily a functional front-end developer. I have experience building apps with React, NextJS, GraphQL, and both Postgres and MongoDB databases.
        </p>
        <p> I am particularly skilled in working with state objects and state management.
        </p>
        <p>I am also highly adept at building API&apos;s using both REST and GraphQL, though I am more fond of GraphQL and Apollo. I have experience with both Apollo Client and Apollo Server.
        </p>
      </div>
      <div className="spacer" />
    </div>
  )
}