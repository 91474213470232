import './TechStack.css'
import adobe from './images/Adobe_Creative_Cloud_rainbow_icon.png'
import axios from './images/logo.svg'
import css from './images/css-3.png'
import express from './images/ExpressJS-logo.png'
import github from './images/github.png'
import html from './images/html-5.png'
import httpie from './images/httpie.png'
import javascript from './images/icons8-javascript-150.png'
import nodejs from './images/icons8-node-js-150.png'
import jest from './images/jest.png'
import markdown from './images/Markdown.png'
import postgres from './images/postgresql.png'
import reactTesting from './images/react-testing.png'
import reactLogo from './images/react.png'
import next from './images/Next.js.png'
import graphql from './images/GraphQL-Logo-Wordmark-Stacked-Rhodamine.png'



export default function TechStack() {

  const tech = [next, graphql, postgres, reactLogo, css, adobe, axios, express, github, html, httpie, javascript, nodejs, jest, markdown, reactTesting]


  return (
  <>
  <div className='technology'>
    <h3 id='heading'>Technical Competencies</h3>
    <div className='stack'>
      <div className='scroller'>
        <div className='techStack'>
          {tech.map((item, id) => <img key={id} id='tech' src={item} alt='{item} logo' />)}
          {tech.map((item, id) => <img key={id} id='tech' src={item} alt='{item} logo' />)}
        </div>
      </div>
    </div>
  </div>
    </>
  )
}
