import {
  useContext,
  createContext,
  useState,
  useEffect
} from 'react'

const AppContext = createContext()


export function AppProvider({ children }) {

  const [state, setState] = useState({
    contactForm: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      subject: '',
      subjectLength: 160
    },
    //state for quote generator
    quotes: [
      '"For an underdog, I\'ve been ridin\' high" - Larkin Poe(from the song Self Made Man)',
      ' "Move fast and break things." - Mark Zuckerberg',
      ' "All you need is 20 seconds of insane courage and I promise you, great things will happen." - Matt Damon',
      ' "Around here, however, we don\'t look backwards for very long. We keep moving forward, opening up new doors and doing new things, because we\'re curious... and curiosity keeps leading us down new paths." - Walt Disney ',
      '"If you sit in the problem long enough, the solution will find you" - Owen Wilson',
      '"Where there is hope, there is life." - Viktor Frankl',
      '"The enemy\'s gate is down." - Ender Wiggin(From the novel Ender\'s Game by Orson Scott Card.',
      '"There ain\'t no such thing as a free lunch." - Robert A. Heinlein(From the novel The Moon is a Harsh Mistress)',
    ],
  })

  useEffect(() => {
    let quote = state.quotes[(Math.floor(Math.random() * state.quotes.length))]
    setState(state =>({
      ...state,
      favoriteQuote: quote,
    }))
  }, [state.quotes])

  return (
    <AppContext.Provider value={
      { state, setState }
    }>
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}